import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/beer-factory-blog/beer-factory-blog/src/templates/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dans ce première article nous allons aborder les rudiments vous permettant d'aborder le monde merveilleux des brasseurs!`}</p>
    <h1>{`Les ingrédients`}</h1>
    <p>{`Une bière c'est au minimum 4 éléments. De l'eau, du malt, du houblon et des levures.`}</p>
    <h2>{`Les malts`}</h2>
    <p>{`Beaucoup de personnes non avertis ont tendance à penser qu'il s'agit d'une céréale particulière.
Que nenni! il est possible de trouver des malts de blés, de seigles, de riz etc. pour faire simple il s'agit de grains que l'on met à germer qui passeront pas de multi étape pour donner un produit riche en amidon.`}</p>
    <h2>{`Les houblons`}</h2>
    <p>{`Cet ingrédient n'était pas présent à l'origine. Les premières trace de ce breuvage malté remonte à -4000 avant J.C. alors que le houblons lui n'a pas été utilisé avant le XII ème siècle.
A l'origine il était utilisé principalement pour ces vertus antiseptiques. Cependant, au fil du temps et des selections d'espèces les brasseurs et producteurs de houblons en ont fait un ingrédient aromatique à part entière.`}</p>
    <h2>{`Les levures`}</h2>
    <p>{`Nous le verrons en détail dans le prochain paragraphe mais cet élément est essentiel dans le processus de fermentation. Les levures sont en fait des organismes vivants qui aime beaucoup le sucre. Elle vont en effet le consommer et produire de l'alcool et du CO2. Ce CO2 s'il est contraint dans une bouteille par exemple se dissoudra dans le liquide afin de lui donner des bulles !`}</p>
    <h2>{`L'eau`}</h2>
    <p>{`To do`}</p>
    <h1>{`Le procédé`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      